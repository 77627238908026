import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Img from 'gatsby-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout/Layout';
import FeatureCard from '../components/Card';
import SplitSection from '../components/SplitSection';
import Callout from '../components/Callout';
import CalloutSimple from '../components/CalloutSimple';
import Seo from '../components/Seo';
import ArrowDown from '../components/ArrowDown';

import one from '../assets/numbers/one.png';
import two from '../assets/numbers/two.png';
import three from '../assets/numbers/three.png';

import connect from '../assets/drawings/connect.png';
import deploy from '../assets/drawings/deploy.png';
import test from '../assets/drawings/test.png';
import testpicto from '../assets/drawings/testpicto.png';
import democonvert from '../assets/drawings/democonvert.png';
import verticalline from '../assets/drawings/verticalline.png';
import tick from '../assets/drawings/tick.png';
import flexible from '../assets/drawings/flexible.png';
import team from '../assets/drawings/team.png';

import batch from '../data/images/customers/batch.png';
import synalabs from '../data/images/customers/synalabs.png';

import demo from '../assets/demo.webm';

export default () => {
  const title = 'On-Demand Environments, For Product-Driven Teams.';
  const description =
    'Strio creates on-demand and easy to set-up environments, to perform all your tests and generate previews.';
  const url = 'https://strio.app';

  const images = useStaticQuery(graphql`
    query HomeQueryTestEnvs {
      tools: allFile(filter: { relativeDirectory: { eq: "images/tools" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(height: 48) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title={title} description={description} url={url} />

      <section id="hero" className="relative pa-0 bg-lownight overflow-hidden">
        <div className="background" />

        <div className="absolute w-full flex flex-row justify-center bottom-0">
          <AnchorLink
            href="#customers"
            className="h-16 z-1 mb-4 lg:mb-6 rounded-full hover:bg-gray-300 transition duration-300 ease-in-out"
          >
          </AnchorLink>
        </div>

        <div className="flex items-center min-h-screen relative">
          <div className="container pt-24 pb-16 mb-12 flex flex-col-reverse lg:flex-row px-4">
            <div className="lg:w-2/5 lg:pr-8">
              <div className="text-center text-white md:text-left">
                <h1 className="mt-4 md:mt-10 text-2xl md:text-4xl xl:text-5xl font-bold leading-tight">
                  Deploy Foolproof Code To Production.
                </h1>
                <p className="font-light leading-snug text-base lg:text-xl mt-6 tracking-tight">
                  Boost your development workflow with on-demand test environments. 
                  Strio handles all the automation to enable you to deploy 
                  isolated environments in minutes, directly from your code.
                </p>
              </div>
              <Callout />
            </div>
            <div className="lg:w-3/5 flex items-center">
              <div className="shadow-lg rounded-md overflow-hidden">
                <video muted autoPlay loop src={demo} type="video/webm" alt="Strio demonstration" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
      <div className="pb-12">
        <SplitSection
          reverseOrder
          primarySlot={
          <img
            src={testpicto}
            alt="A drawing of the testing process"
            className="mx-auto h-auto lg:w-full"
          />
          }  
          secondarySlot={
            <div className="sm:px-12 lg:pr-32 lg:pl-6 xl:pr-12 xl:pr-6">
              <h3 className="text-2xl md:text-4xl font-semibold leading-tight font-display text-lownight">
                Testing environments <br/> to boost development
              </h3>
              <div className="mt-6 text-base md:text-xl leading-relaxed text-gray-700">
                <p> Deploy a testing environment per branch. Isolate tests to detect bugs more efficiently and accelerate the development process.
                </p> 
                <Link
                  className="text-base w-2/3 inline-flex items-center justify-center px-8 py-3 my-8 border border-transparent leading-6 font-bold tracking-wide rounded-md text-white bg-codestream hover:bg-cloud focus:outline-none focus:shadow-outline transition duration-300 ease-in-out"
                  to="/usecases/testenv"
                >
                  Learn More
                </Link>
              </div>
            </div>
          }
        />
      </div>
      </section>

      <section className="bg-gray-200 shadow-inner pb-8">
        <div className="container mx-auto text-center pt-20">
          <h3 className="text-2xl md:text-4xl font-extrabold leading-snug lg:w-2/3 px-6 pb-4 mx-auto tracking-tight text-lownight font-display">
            Strio powers the workflow of your entire team
          </h3>
        </div>
        <div className="container md:my-10 my-4 px-6">
          <div className="mt-8 flex flex-col lg:mx-12 sm:flex-row sm:mx-3">
            
            <div className="flex flex-col flex-1 p-3 mx-1 text-center md:text-right">
              <div className="flex flex-col justify-start">
                <p className="font-semibold text-2xl font-display text-lownight">
                  For Ops
                </p>
                <p className="mt-4 text-base md:text-xl text-gray-700">
                  Strio is configured by the Ops to be adjusted to the company's requirements.
                </p>
              </div>
              
              <div className="flex-1" />

              <div className="flex flex-col pt-6">
                <div className="flex flex-col justify-end">
                  <p className="font-semibold text-2xl font-display text-lownight">
                    For Product teams 
                  </p>
                  <p className="mt-4 text-base md:text-xl text-gray-700">
                    Product Managers and Sales Representatives can deploy their own pre-defined environments for previews or demos.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="p-3 mx-6 hidden md:block">
              <div>
              <img src={verticalline} 
              alt="Vertical line" 
              className="mx-auto my-4 w-16" />
              </div>
            </div>

            <div className="flex flex-1 p-3 mx-1">
              <div className="flex flex-col justify-center text-center md:text-left">
                <p className="font-semibold text-2xl font-display text-lownight">
                  For Developers and QA 
                </p>
                <p className="mt-4 text-base md:text-xl text-gray-700">
                Developers and QA are fully autonomous on Strio's interface to deploy the environments which meet their testing needs. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white shadow-inner">
        <div className="container mx-auto px-6 md:px-12 pt-20 text-center">
          <h3 className="text-2xl md:text-4xl font-extrabold leading-snug lg:w-2/3 px-0 md:px-8 mx-auto tracking-tight text-lownight font-display">
            Create your environment in just three steps.
          </h3>
          <div className="mt-12 flex flex-col md:flex-row">
            <div className="flex-1 p-3">
              <img src={one} style={{ height: '48px' }} alt="One icon" className="mx-auto my-4" />
              <div className="flex flex-col justify-between">
                <p className="font-semibold text-2xl font-display text-lownight text-center">
                  Connect your repositories
                </p>
                <img src={connect} alt="Three icon" className="hidden md:block" />
              </div>
            </div>
            <div className="flex-1 p-3">
              <img src={two} style={{ height: '48px' }} alt="Two icon" className="mx-auto my-4" />
              <div className="flex flex-col justify-between">
                <p className="font-semibold text-2xl font-display text-lownight text-center">
                  Deploy at every pull request
                </p>
                <img src={deploy} alt="Three icon" className="hidden md:block" />
              </div>
            </div>
            <div className="flex-1 p-3">
              <img
                src={three}
                style={{ height: '48px' }}
                alt="Three icon"
                className="mx-auto my-4"
              />
              <div className="flex flex-col justify-between">
                <p className="font-semibold text-2xl font-display text-lownight text-center">
                  Test your services
                </p>
                <img src={test} alt="Three icon" className="hidden md:block" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-200 shadow-inner pb-8">
        <div className="container mx-auto text-center pt-20">
          <h3 className="text-2xl md:text-4xl font-extrabold leading-snug lg:w-2/3 px-0 md:px-6 pb-4 mx-auto tracking-tight text-lownight font-display">
            Why Strio?
          </h3>
        </div>
        <div className="container text-center mb-4 px-0 md:px-6">
          <div className="mb-4 flex flex-col lg:mx-12 sm:flex-row sm:mx-3">
            <div className="flex-1 p-3 mx-1">
              <div>
                <img src={tick} style={{ height: '60px' }} alt="tick icon" className="mx-auto my-6" />
                <p className="font-semibold text-2xl font-display text-lownight">
                  Complete environments
                </p>
                <p className="mt-4 text-base md:text-xl text-gray-700">
                Deploy what you need to perform effective tests or 
                successful demos: containers, dependencies and data.
                </p>
              </div>
            </div>
            <div className="flex-1 p-3 mx-1">
              <div>
              <img src={team} style={{ height: '60px' }} alt="team icon" className="mx-auto my-6" />
                <p className="font-semibold text-2xl font-display text-lownight">
                  Empower your team
                </p>
                <p className="mt-4 text-base md:text-xl text-gray-700">
                  Empower your developers, sales representatives and product managers to leverage the power of infrastructure.
                </p>
              </div>
            </div>
            <div className="flex-1 p-3 mx-1">
              <div>
              <img src={flexible} style={{ height: '60px' }} alt="gear icon" className="mx-auto my-6" />
                <p className="font-semibold text-2xl font-display text-lownight">
                  A flexible solution
                </p>
                <p className="mt-4 text-base md:text-xl text-gray-700">
                Whether you have hundreds of microservices or few components, Strio makes it easy to deploy environments. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-white py-12 shadow-inner">
        <div className="container mx-auto text-center py-12 px-6 lg:px-20">
          <h3 className="text-2xl md:text-4xl font-extrabold leading-snug lg:w-2/3 px-3 mx-auto tracking-tight text-lownight font-display">
            Integrates with all your tools
          </h3>
          <p className="text-base md:text-xl mt-4 md:mt-12 text-gray-700 px-0 md:px-8">
            Strio supports multi-cloud and hybrid cloud configurations and integrates with your
            favorite tools, so you can continue to work with the tools that you use and love.
          </p>
          <div className="mt-8 sm:mx-3 grid grid-cols-2 lg:grid-cols-5">
            {images.tools.edges.map(tool => (
              <div key={tool.node.name} className="flex-1 p-3">
                <FeatureCard>
                  <Img
                    fixed={tool.node.childImageSharp.fixed}
                    alt={tool.node.name}
                    className="mx-auto my-2"
                  />
                </FeatureCard>
              </div>
            ))}
          </div>
        </div>
      </section>

      
      <section className="bg-gray-100">
        <CalloutSimple />
      </section>
    </Layout>
  );
};
